import React from 'react'
import moment from 'moment'
import BigCalendar from 'react-big-calendar'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'

import { getEvents } from '../services/google_calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = BigCalendar.momentLocalizer(moment);

class Schedule extends React.Component {
  constructor () {
    super()
    this.state = {
      events: []
    }
  }

  componentDidMount () {
    getEvents((events) => {
      this.setState({events})
    })
  }

  render () {
    const postNode = {
      title: `Schedule - ${config.siteTitle}`,
    }

    return (
      <Layout>
        <Helmet>
          <title>{`Schedule - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath="schedule" customTitle />
  
        <Container>
          <BigCalendar
            style={{height: '420px'}}
            localizer={localizer}
            events={this.state.events}
            startAccessor="start"
            endAccessor="end"
          />
        </Container>
      </Layout>
    )
  }
}

export default Schedule
