import axios from 'axios'

const CALENDAR_ID = '8dq2214fhfvd6897ftsbjbn5pg@group.calendar.google.com';
const API_KEY = 'AIzaSyDKmp8ZexxnzeTDznLcypHO5PHgLkFvKGw';
let url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`

export function getEvents (callback) {
    axios.get(url)
        .then(response => {
            const events = [];
            response.data.items.map((event) => {
                events.push({
                    id: event.id,
                    start: new Date(event.start.dateTime),
                    end: new Date(event.end.dateTime),
                    title: event.summary || "Booked",
                })
            })
            callback(events)
        })
        .catch(error => {
            console.log(error);
        })
}